import React, { useEffect, useState } from "react";

import "./VarientSelector.scss";
import ButtonStandard from "../../atoms/ButtonStandard/ButtonStandard";
import { IMAGE_VIEWER, IMAGE_COMPARER } from "../../constants";

const VarientSelector = ({
  action = () => {},
  setColorCode = () => {},
  productVarients = {},
  productPatterns = {},
  loading = false,
  selectedScreen = "",
  colorCode = "",
  appliedEffects = "",
}) => {
  const [selectedVarient, setSelectedVarient] = useState("");
  const [selectedPattern, setSelectedPattern] = useState("");

  const varientHeaderText = () => (
    <div className="varient-header-text">
      <h1 className="title-text">
        <strong>{productVarients.headerTitle}</strong>
      </h1>
      <p className="header-text">{productVarients.headerText}</p>
    </div>
  );

  const varientTabHeader = () => {
    const numbersOfColourVariationHeaders =
      productVarients.varients?.colors?.[selectedVarient?.split("-").join(" ")]
        ?.length || 0;
    return (
      <div className="varient-tab-header">
        <div
          className="varient-tabs"
          style={{
            gridTemplateColumns: `repeat(${
              Object.keys(productVarients.varients || {}).length + 1
            }, 1fr)`,
            display: numbersOfColourVariationHeaders ? "grid" : "none",
          }}
        >
          {productVarients.varients?.colors
            ? Object.keys(productVarients.varients?.colors).map(
                (varient, index) => {
                  const varientId = varient
                    ?.trim()
                    ?.toLowerCase()
                    .split(" ")
                    .join("-");
                  return (
                    <div
                      className={`${varientId} tab ${
                        selectedVarient === varientId ? "selected" : ""
                      }`}
                      key={`${varientId}-${index}`}
                      onClick={() => setSelectedVarient(varientId)}
                    >
                      {varient}
                    </div>
                  );
                }
              )
            : ""}
        </div>
      </div>
    );
  };

  const varientTabContent = () => {
    const numbersOfColourVariations =
      productVarients.varients?.colors?.[selectedVarient?.split("-").join(" ")]
        ?.length || 0;
    return (
      <div className="varient-tab-content">
        <div
          className="varient-palette"
          style={{
            gridTemplateColumns: `repeat(${numbersOfColourVariations}, 3rem)`,
            display: numbersOfColourVariations ? "grid" : "none",
          }}
        >
          {productVarients.varients?.colors?.[
            selectedVarient?.split("-").join(" ")
          ]?.map((color, index) => {
            return (
              <div className="paletter-container">
                <div
                  className={`palette-color ${
                    colorCode === color ? "selected" : ""
                  }`}
                  style={{ backgroundColor: color }}
                  key={`${color}-${index}`}
                  onClick={() => {
                    if (color !== colorCode) {
                      action({ type: productVarients.product, color });
                      setColorCode(color);
                    }
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const patternTabHeader = () => {
    const numberOfPatternHeaders =
      productPatterns?.[selectedPattern?.split("-").join(" ")]?.length || 0;
    return Object.keys(productPatterns).length ? (
      <div className="varient-tab-header pattern">
        <div
          className="varient-tabs"
          style={{
            gridTemplateColumns: `repeat(${numberOfPatternHeaders}, 1fr)`,
            display: numberOfPatternHeaders ? "grid" : "none",
          }}
        >
          {productPatterns
            ? Object.keys(productPatterns).map((pattern, index) => {
                const patternId = pattern
                  ?.trim()
                  ?.toLowerCase()
                  .split(" ")
                  .join("-");
                return (
                  <div
                    className={`${patternId} tab ${
                      selectedPattern === patternId ? "selected" : ""
                    }`}
                    key={`${patternId}-${index}`}
                    onClick={() => setSelectedVarient(patternId)}
                  >
                    {pattern}
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    ) : (
      ""
    );
  };

  const patternTabContent = () => {
    const numberOfPatternTypes =
      productPatterns?.[selectedPattern?.split("-").join(" ")]?.length || 0;
    return Object.keys(productPatterns).length ? (
      <div className="varient-tab-content pattern">
        <div
          className="pattern-palette"
          style={{
            gridTemplateColumns: `repeat(${numberOfPatternTypes}, 3.75rem)`,
            display: numberOfPatternTypes ? "grid" : "none",
          }}
        >
          {productPatterns?.[selectedPattern.split("-").join(" ")]?.map(
            (pattern, index) => {
              return (
                <div
                  data-pattern-title={pattern.title}
                  className={`palette-pattern ${
                    appliedEffects[Object.keys(productPatterns)?.[0]] ===
                    pattern
                      ? "selected"
                      : ""
                  }`}
                  style={{ backgroundImage: `url(${pattern.url})` }}
                  key={`${pattern}-${index}`}
                  onClick={() =>
                    action({
                      type: productVarients.product,
                      pattern: pattern.id,
                    })
                  }
                ></div>
              );
            }
          )}
        </div>
      </div>
    ) : (
      ""
    );
  };

  const varientDescription = () => (
    <p className="varient-description">{productVarients.description}</p>
  );

  const storeVarientActions = () => (
    <div className="store-varient-actions">
      <ButtonStandard selected={true} action={() => {}}>
        Get a Business Demo
      </ButtonStandard>
    </div>
  );

  const varientControl = () => (
    <>
      {varientHeaderText()}
      {varientTabHeader()}
      {varientTabContent()}
      {patternTabHeader()}
      {patternTabContent()}
      {varientDescription()}
      {storeVarientActions()}
    </>
  );

  useEffect(() => {
    setSelectedVarient(
      productVarients?.varients?.colors
        ? Object.keys(productVarients?.varients?.colors)?.[0]
            ?.split(" ")
            .join("-")
        : ""
    );
    setSelectedPattern(
      Object.keys(productPatterns)?.[0]?.split(" ").join("-") || ""
    );
  }, [productVarients.varients, productPatterns]);

  return (
    <div className="varient-detail">
      {loading +
        (selectedScreen !== IMAGE_VIEWER &&
          selectedScreen !== IMAGE_COMPARER) >=
      1 ? (
        <div className="varient-overlay"></div>
      ) : (
        ""
      )}
      {productVarients ? varientControl() : ""}
    </div>
  );
};
export default VarientSelector;
