import React, { useEffect, useRef, useState } from "react";
import ButtonIcon from "../../atoms/ButtonIcon/ButtonIcon";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { ReactComponent as Camera } from "../../assets/svg/camera.svg";
import { ReactComponent as Upload } from "../../assets/svg/addPhoto.svg";
import { ReactComponent as Model } from "../../assets/svg/model.svg";
import { ReactComponent as AppLogo } from "../../assets/svg/brand-metapix.svg";
import { ReactComponent as CameraButton } from "../../assets/svg/cameraButton.svg";
import { ReactComponent as Reset } from "../../assets/svg/reset.svg";
import { ReactComponent as ZoomOut } from "../../assets/svg/zoomOut.svg";
import { ReactComponent as ZoomIn } from "../../assets/svg/zoomIn.svg";
import { ReactComponent as Compare } from "../../assets/svg/compare.svg";
import { ReactComponent as Download } from "../../assets/svg/download.svg";
import { ReactComponent as Back } from "../../assets/svg/back.svg";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import Step1 from "../../assets/svg/uploaderStep1.png";
import Step2 from "../../assets/svg/uploaderStep2.png";
import Step3 from "../../assets/svg/uploaderStep3.png";

import { useCamera } from "../../customHooks/useCamera";
import {
  DEFAULT_SCREEN,
  SELECT_MODEL,
  IMAGE_VIEWER,
  IMAGE_COMPARER,
  PHOTO_UPLOADER,
  CAMERA_VIEWER,
  CAMERA_CAPTURE_COUNTDOWN,
  SLIDER_DEFAULT_POSITION,
} from "../../constants";

import "./TryNowVisualizer.scss";

const TryNowVisualizer = ({
  imageEffects = null,
  loading = false,
  predefinedModels = [],
  selectedImageDetails = {},
  selectedScreen,
  baseSelectedImage,
  selectedImageWithoutMakeup,
  action = () => {},
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [comparisonSliderPosition, setComparisonSliderPosition] = useState(
    `${SLIDER_DEFAULT_POSITION * 10}`
  );
  const [afterImageWidth, setAfterImageWidth] = useState(
    `${SLIDER_DEFAULT_POSITION}%`
  );
  const [zoomLevel, setZoomLevel] = useState(1);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [timer, setTimer] = useState(null);
  const [camera, videoRef, canvasRef] = useCamera();
  const flashRef = useRef();
  const photoUploadControl = useRef();
  const beforeImg = useRef();
  const afterImg = useRef();
  const imageResizerCanvas = useRef();

  const defaultScreenOption = (
    <>
      <h2>try it on</h2>
      <div className="option-selector">
        <ButtonIcon
          icon={<Camera />}
          action={() => {
            camera.start();
            action({ action: { type: "set-screen" }, payload: CAMERA_VIEWER });
            setSelectedOption(CAMERA_VIEWER);
            setZoomLevel(1);
          }}
        >
          Take a photo
        </ButtonIcon>
        <ButtonIcon
          icon={<Upload />}
          action={() => {
            action({ action: { type: "set-screen" }, payload: PHOTO_UPLOADER });
            setSelectedOption(PHOTO_UPLOADER);
          }}
        >
          Upload a photo
        </ButtonIcon>
        <ButtonIcon
          icon={<Model />}
          action={() => {
            action({ action: { type: "get-models" } });
            action({ action: { type: "set-screen" }, payload: SELECT_MODEL });
            setSelectedOption(SELECT_MODEL);
          }}
        >
          Use a model
        </ButtonIcon>
      </div>
    </>
  );

  const modelSelector = (
    <>
      <div className="selected-screen-header">
        <span></span>
        <Close
          onClick={() => {
            action({ action: { type: "update-effects" }, payload: null });
            action({ action: { type: "set-screen" }, payload: DEFAULT_SCREEN });
            setSelectedOption(null);
          }}
        />
      </div>
      <div className="model-selector-container">
        <span>choose a model</span>
        <div className="try-now-model-selector">
          {/* <canvas ref={canvasRef} height={500} width={500}></canvas> */}
          {predefinedModels.map((model) => (
            <div
              className="model-image"
              key={model.id}
              style={{ backgroundImage: `url(${model.url})` }}
              onClick={() => {
                action({
                  action: { type: "select-image" },
                  payload: { ...model },
                });
                action({
                  action: { type: "set-screen" },
                  payload: IMAGE_VIEWER,
                });
              }}
            ></div>
          ))}
        </div>
      </div>
    </>
  );

  const uploadPhoto = (
    <>
      <div className="selected-screen-header">
        <span></span>
        <Close
          onClick={() => {
            action({ action: { type: "update-effects" }, payload: null });
            action({ action: { type: "set-screen" }, payload: DEFAULT_SCREEN });
            setSelectedOption(null);
            action({ action: { type: "select-image" }, payload: null });
          }}
        />
      </div>
      <div className="try-now-photo-uploader">
        <p>photo instructions</p>
        <div className="instructions-container">
          <img src={Step1} alt="step1" />
          <div className="step-description">
            <p>step 1</p>
            <div>use a photo that is of the face straight on</div>
          </div>
          <img src={Step2} alt="step2" />
          <div className="step-description">
            <p>step 2</p>
            <div>make sure nothing is obstructing the face</div>
          </div>
          <img src={Step3} alt="step3" />
          <div className="step-description">
            <p>step 3</p>
            <div>make sure lightning is not too dim or overexposed</div>
          </div>
        </div>
        <ButtonIcon
          icon={<Upload />}
          action={() => {
            if (photoUploadControl.current) {
              photoUploadControl.current.click();
            }
          }}
        >
          Upload a photo
        </ButtonIcon>
      </div>
    </>
  );

  const takeAPic = (
    <>
      <div className="selected-screen-header">
        <span></span>
        <Close
          onClick={() => {
            camera.stop();
            action({ action: { type: "update-effects" }, payload: null });
            action({ action: { type: "set-screen" }, payload: DEFAULT_SCREEN });
            setSelectedOption(null);
            action({ action: { type: "select-image" }, payload: null });
          }}
        />
      </div>
      <div className="try-now-camera-viewer">
        <canvas ref={canvasRef} height={380} width={380}></canvas>
        {timer ? (
          <div className="timer-overlay">
            taking photo in <div className="counter">{timer}</div>
          </div>
        ) : (
          ""
        )}
        <video ref={videoRef} autoPlay playsInline muted />
        <CameraButton
          onClick={() => {
            let countDown = CAMERA_CAPTURE_COUNTDOWN;
            setTimer(countDown);
            const timer = setInterval(() => {
              setTimer((counter) => (countDown > 0 ? countDown : counter));
              if (countDown === 1) {
                if (flashRef.current) {
                  flashRef.current.style.display = "block";
                  flashRef.current.style.animationName = "flash";
                  clearInterval(timer);
                  setTimer(null);
                }
              } else {
                countDown--;
              }
            }, 1000);
          }}
        />
      </div>
    </>
  );

  const imageViewerScreen = (
    <>
      <div className="selected-screen-header">
        <Back
          onClick={() => {
            if (selectedOption === CAMERA_VIEWER) {
              camera.start();
            }
            action({ action: { type: "set-screen" }, payload: selectedOption });
            setZoomLevel(1);
          }}
        />
        <span></span>
        <Close
          onClick={() => {
            action({ action: { type: "update-effects" }, payload: null });
            action({ action: { type: "set-screen" }, payload: DEFAULT_SCREEN });
            setSelectedOption(null);
            action({ action: { type: "select-image" }, payload: null });
            setZoomLevel(1);
          }}
        />
      </div>
      <div className="captured-image-viewer">
        <img
          className="captured-image"
          src={`${
            selectedImageDetails?.processedURL || selectedImageDetails?.url
          }`}
          style={{
            transform: `scale(${zoomLevel})`,
          }}
        />

        <div className="captured-image-actions">
          <Reset
            onClick={() => {
              action({
                action: { type: "select-image" },
                payload: { ...selectedImageWithoutMakeup, processedURL: "" },
              });
              setZoomLevel(1);
            }}
          />
          <ZoomOut
            onClick={() => {
              setZoomLevel((zoom) => (zoom > 1 ? zoom - 0.5 : zoom));
            }}
          />
          <ZoomIn
            onClick={() => {
              setZoomLevel((zoom) => (zoom < 3 ? zoom + 0.5 : zoom));
            }}
          />
          <Compare
            className="image-action-compare"
            onClick={
              selectedImageDetails?.processedURL
                ? () => {
                    setComparisonSliderPosition("500");
                    setAfterImageWidth("50%");
                    action({
                      action: { type: "set-screen" },
                      payload: IMAGE_COMPARER,
                    });
                  }
                : () => {}
            }
            style={
              selectedImageDetails?.processedURL
                ? { backgroundColor: "inherit", opacity: 1 }
                : { backgroundColor: "#ffffff", opacity: 0.5 }
            }
          />
          <a
            href={
              selectedImageDetails?.processedURL
                ? selectedImageDetails?.processedURL
                : "#"
            }
            download={selectedImageDetails?.processedURL ? true : false}
          >
            <Download
              style={
                selectedImageDetails?.processedURL
                  ? { backgroundColor: "inherit", opacity: 1 }
                  : { backgroundColor: "#ffffff", opacity: 0.5 }
              }
            />
          </a>
        </div>
      </div>
    </>
  );

  const imageComparisonScreen = (
    <>
      <div className="selected-screen-header">
        <Back
          onClick={() => {
            action({ action: { type: "set-screen" }, payload: IMAGE_VIEWER });
          }}
        />
        <span></span>
        <Close
          onClick={() => {
            action({ action: { type: "update-effects" }, payload: null });
            action({ action: { type: "set-screen" }, payload: DEFAULT_SCREEN });
            setSelectedOption(null);
            action({ action: { type: "select-image" }, payload: null });
          }}
        />
      </div>
      <div className="processed-image-container">
        <div className="image-comparer">
          <div className="image-container">
            <img
              className="before-img"
              ref={beforeImg}
              src={selectedImageWithoutMakeup?.url}
              alt="before"
              style={{ width: afterImageWidth }}
            />
          </div>
          <div className="image-container">
            <img
              className="after-img"
              ref={afterImg}
              src={selectedImageDetails?.processedURL}
              alt="after"
            />
          </div>
          <input
            type="range"
            min="0"
            max="1000"
            value={comparisonSliderPosition}
            className="comparisonSlider"
            onChange={(e) => {
              const slidedPercentage = e.target.value;
              setComparisonSliderPosition(slidedPercentage);
              setAfterImageWidth(`${+slidedPercentage / 10}%`);
            }}
          />
          <div
            className="comparisonSlider-line"
            style={{ left: afterImageWidth }}
          ></div>
          <div
            className="comparisonSlider-button"
            style={{ left: afterImageWidth }}
          ></div>
        </div>
      </div>
    </>
  );

  const optionsScreenCollection = {
    [DEFAULT_SCREEN]: defaultScreenOption,
    [SELECT_MODEL]: modelSelector,
    [PHOTO_UPLOADER]: uploadPhoto,
    [CAMERA_VIEWER]: takeAPic,
    [IMAGE_VIEWER]: imageViewerScreen,
    [IMAGE_COMPARER]: imageComparisonScreen,
  };

  useEffect(() => {
    if (imageEffects) {
      const payload = {
        user_id: selectedImageDetails?.id,
        makeup_color: imageEffects.color,
        makeup_type: imageEffects.type,
        makeup_pattern: imageEffects.pattern,
      };
      action({ action: { type: "process-image" }, payload });
    }
  }, [imageEffects, selectedImageDetails?.id, action]);

  useEffect(() => {
    if (selectedPhoto) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (imageResizerCanvas.current) {
          const context = imageResizerCanvas.current.getContext("2d");
          const imagePlaceholder = new Image();
          imagePlaceholder.onload = () => {
            context.drawImage(
              imagePlaceholder,
              0,
              0,
              imageResizerCanvas.current.width,
              imageResizerCanvas.current.height
            );
            const payload = {
              file: imageResizerCanvas.current
                .toDataURL("image/webp")
                .toString()
                .replace(/^data:(.*,)?/, ""),
            };
            action({ action: { type: "upload-image" }, payload });
            action({ action: { type: "set-screen" }, payload: IMAGE_VIEWER });
            setSelectedPhoto(null);
            context.clearRect(
              0,
              0,
              imageResizerCanvas.current.width,
              imageResizerCanvas.current.height
            );
          };
          imagePlaceholder.src = URL.createObjectURL(selectedPhoto);
        }
      };
      reader.readAsDataURL(selectedPhoto);
    }
  }, [selectedPhoto, action]);

  const visualizer = (
    <div className="try-now-visualizer-container">
      {loading && (
        <div className="logo-container">
          <Logo className="logo" />
        </div>
      )}
      <div
        className="flash"
        ref={flashRef}
        onAnimationEnd={() => {
          if (flashRef.current) {
            flashRef.current.style.display = "none";
            flashRef.current.style.animationName = "unset";
            const capturedImageDataUrl = camera.capture();
            camera.stop();
            const payload = {
              file: capturedImageDataUrl.toString().replace(/^data:(.*,)?/, ""),
            };
            action({ action: { type: "upload-image" }, payload });
            action({ action: { type: "set-screen" }, payload: IMAGE_VIEWER });
          }
        }}
      ></div>
      <input
        className="photo-upload-control"
        type="file"
        onInput={(e) => setSelectedPhoto(e.target.files[0])}
        onClick={(e) => (e.target.value = null)}
        ref={photoUploadControl}
      />
      <canvas
        ref={imageResizerCanvas}
        height={500}
        width={500}
        style={{ display: "none" }}
      />
      <div className="try-now-visualizer">
        {optionsScreenCollection[selectedScreen]}
        <div className="caption">
          <div>powered by</div>
          <AppLogo />
        </div>
      </div>
    </div>
  );
  return visualizer;
};
export default TryNowVisualizer;
