import React from 'react';
import './App.scss';
import DemoStore from './organisms/DemoStore';

function App() {

  return <DemoStore />;
}

export default App;
