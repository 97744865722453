import React from "react";
import categories from '../../metadata/categories';
import ButtonPills from "../../atoms/ButtonPills/ButtonPills";
import {CATEGORY_LIP_COLOR} from "../../constants";
import './CategorySelector.scss'

const CategorySelector = ({action=()=>{}, selectedCategory = CATEGORY_LIP_COLOR}) =>{
    const categorySection = <section className="category-section-container">
            <div className="category-section">
                <h2 className="section-title">Demo store</h2>
                <div className="categories-container">
                    <div className="categories">
                        {categories().map((category, index)=> <ButtonPills
                            key={`${category.id}-${index}`}
                            selected={selectedCategory===category.id}
                            action={()=>action(category.id)}
                            >
                                {category.title}
                            </ButtonPills>
                        )}
                    </div>
                </div>
            </div>
        </section>
    return categorySection
}
export default CategorySelector;