import SimpleService from "./SimpleService";
import urlDictionary from "./urlDictionary";

import { ENVIRONMENT } from '../appConfig';

const PostCategoryMetaInfo = ({ method='POST', payload={}, csrftoken=''}) => {
    const url = urlDictionary(ENVIRONMENT).postCategoryMetaInfo();
    const header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken
        };

    const serviceParams = ENVIRONMENT === 'mock' ? {
        method: 'GET',
        header
    } : {
        method,
        headers: header,
        body: JSON.stringify(payload)
    }

    return SimpleService({url, serviceParams});
};

export default PostCategoryMetaInfo;