import {
    COLORS,
    PATTERNS,
    CATEGORY_LIP_COLOR,
    CATEGORY_LIP_LINER,
    CATEGORY_EYE_LINER,
    CATEGORY_EYE_SHADOW,
    CATEGORY_MASCARA,
    BLUSH
} from "../constants"; 

const CategoryVarientPostProcessor = (category, data) => {

    const lipColorPostProcessor = () =>{
        return {productVariantDetails : data};
    }
    
    const lipLinerPostProcessor = () =>{
        const productVariantDetails = data;
        const lipShapes = {[PATTERNS]: productVariantDetails.varients[PATTERNS]};
        productVariantDetails.varients = {[COLORS]: productVariantDetails.varients[COLORS]};
        return {productVariantDetails, ...lipShapes};
    }

    const eyeLinerPostProcessor = () =>{
        return {productVariantDetails : data};
    }

    const eyeShadowPostProcessor = () =>{
        const productVariantDetails = data;
        const shadowPattern = {[PATTERNS]: productVariantDetails.varients[PATTERNS]};
        productVariantDetails.varients = {[COLORS]: productVariantDetails.varients[COLORS]};
        return {productVariantDetails, ...shadowPattern};
    }

    const mascaraPostProcessor = () =>{
        const productVariantDetails = data;
        const mascaraPattern = {[PATTERNS]: productVariantDetails.varients[PATTERNS]};
        productVariantDetails.varients = {[COLORS]: productVariantDetails.varients[COLORS]};
        return {productVariantDetails, ...mascaraPattern};
    }

    const postProcessorMapping = {
        [CATEGORY_LIP_COLOR]: lipColorPostProcessor,
        [CATEGORY_LIP_LINER]: lipLinerPostProcessor,
        [CATEGORY_EYE_LINER]: eyeLinerPostProcessor,
        [CATEGORY_EYE_SHADOW]: eyeShadowPostProcessor,
        [CATEGORY_MASCARA]: mascaraPostProcessor,
        [BLUSH]: mascaraPostProcessor
    }

    return postProcessorMapping[category]
}
export default CategoryVarientPostProcessor;