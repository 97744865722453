import React from "react";
import './ButtonStandard.scss';

const ButtonStandard = ({action=()=>{}, enabled=true, children={}, selected=false}) => {
    const redirectTocalendly = () => {
        window.location.href = 'https://calendly.com/meet-inigo/';
      };
    const el_standardButton = <div
        className={`metapix-button-standard ${!enabled && 'disabled'} ${selected ? 'selected' : ''}`}
        onClick={enabled ? ()=>{redirectTocalendly()} : () => {}}>
            {children}
    </div>
    return el_standardButton;
}
export default ButtonStandard;