import React from "react";
import './ButtonPills.scss';

const ButtonPills = ({action=()=>{}, enabled=true, children={}, selected=false}) => {
    const el_standardButton = <div
        className={`metapix-button-pills ${!enabled && 'disabled'} ${selected ? 'selected' : ''}`}
        onClick={enabled ? ()=>{action()} : () => {}}>
            {children}
    </div>
    return el_standardButton;
}
export default ButtonPills;