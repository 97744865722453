import React from "react";
import './ButtonIcon.scss';

const ButtonIcon = ({action=()=>{}, icon=null, enabled=true, children={}, selected=false}) => {
    const el_standardButton = <div
        className={`metapix-button-icon ${!enabled && 'disabled'} ${selected ? 'selected' : ''}`}
        onClick={enabled ? ()=>{action()} : () => {}}>
            {icon ? icon : ''}
            {children}
    </div>
    return el_standardButton;
}
export default ButtonIcon;