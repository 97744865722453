import SimpleService from "./SimpleService";
import urlDictionary from "./urlDictionary";
import {ENVIRONMENT} from '../appConfig'

const PostProcessImage = ({method='POST', payload={}, csrftoken='', signal={}}) => {
    const url = urlDictionary(ENVIRONMENT).postProcessImage();
    const header = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'X-CSRFToken': csrftoken
        };

    const serviceParams = ENVIRONMENT === 'mock' ? {
        method: 'GET',
        header
    } : {
        method,
        headers: header,
        body: JSON.stringify(payload)
    }

    return SimpleService({url, serviceParams, signal});
};

export default PostProcessImage;