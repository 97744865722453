import SimpleService from "./SimpleService";
import urlDictionary from "./urlDictionary";
import { ENVIRONMENT } from '../appConfig';

const GetPredefinedModels = ({method='GET', payload={}, csrftoken='', signal={}}) => {
    const url = urlDictionary(ENVIRONMENT).getPredefinedModels();
    const header = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'X-CSRFToken': csrftoken
        };

    const serviceParams = ENVIRONMENT === 'mock' ? {
        method: 'GET',
        header
    } : {
        method,
        headers: header,
    }

    return SimpleService({url, serviceParams, signal});
};

export default GetPredefinedModels;