import React, { useState } from "react";


import {ReactComponent as AppLogo} from '../../assets/svg/brand-metapix.svg';


import './Header.scss';

const Header = () =>{

    const redirectToMetapix = () => {
        window.location.href = 'https://metapix.me/ai-editor/virtual-fitting-room/virtual-makeup-try-on/'; // Replace with your desired URL
      };
   
    const header = <header className="app-header-container">
           
            <div className="app-header">
              
                <AppLogo className="app-logo" onClick={redirectToMetapix} />
                    
            </div>
        </header>

    return header;
}
export default Header;