import { useEffect, useRef, useState } from "react";
import {VIDEO_FPS, CAMERA_STATE_START, CAMERA_STATE_STOP} from '../constants';

export const useCamera = (videoEnabled=true, audioEnabled=false, defaultFacingMode='user') =>{
    const [cameraStream, setCameraStream] = useState(null);
    const videoRef = useRef();
    const canvasRef = useRef();
    const [cameraState, setCameraState] = useState(CAMERA_STATE_STOP);
    const canvasFramesRef = useRef();

    useEffect(()=>{
        if(videoRef.current){
            videoRef.current.srcObject = cameraStream;
            if(cameraStream){
                videoRef.current.play();
            } else{
                videoRef.current.pause();
            }
        }
    },[cameraStream]);

    const createCameraFeed = () =>{
        return setInterval(()=>{
            if(canvasRef.current){
                const context = canvasRef.current.getContext("2d");
                context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
            }
        }, 1000/VIDEO_FPS);
    }

    useEffect(()=>{
        if(canvasFramesRef.current){
            clearInterval(canvasFramesRef.current);
            canvasFramesRef.current = null;
            if(cameraState === CAMERA_STATE_START){
                canvasFramesRef.current = createCameraFeed();
            } else{
                clearInterval(canvasFramesRef.current);
                canvasFramesRef.current = null;
            }
        }else {
            canvasFramesRef.current = createCameraFeed();
        }
    },[cameraState]);

    const camera = {
        start : () =>{
            if (!cameraStream) {
                navigator.mediaDevices.getUserMedia({ video: videoEnabled, audioEnabled: audioEnabled, facingMode: defaultFacingMode })
                    .then(stream => {
                        setCameraStream(stream);
                        setCameraState(CAMERA_STATE_START);
                    });
            }
        },
        stop : () =>{
            if (cameraStream) {
                cameraStream.getTracks().forEach(track => {
                    track.stop();
                    setCameraStream(null);
                    setCameraState(CAMERA_STATE_STOP);
                });
            }
        },
        capture : () =>{
            setCameraState(CAMERA_STATE_STOP);
            return canvasRef.current.toDataURL("image/webp");
        }
    }
    return [camera, videoRef, canvasRef]
}