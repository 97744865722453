import {
    CATEGORY_LIP_COLOR,
    CATEGORY_LIP_LINER,
    CATEGORY_EYE_LINER,
    CATEGORY_EYE_SHADOW,
    CATEGORY_MASCARA,
    BLUSH
} from '../constants';

const categories = () =>{

    return [
        {
            id: CATEGORY_LIP_COLOR,
            title: CATEGORY_LIP_COLOR.split('-').join(' ')
        },
        {
            id: CATEGORY_LIP_LINER,
            title: CATEGORY_LIP_LINER.split('-').join(' ')
        },
        {
            id: CATEGORY_EYE_LINER,
            title: CATEGORY_EYE_LINER.split('-').join(' ')
        },
        {
            id: CATEGORY_EYE_SHADOW,
            title: CATEGORY_EYE_SHADOW.split('-').join(' ')
        },
        {
            id: CATEGORY_MASCARA,
            title: CATEGORY_MASCARA.split('-').join(' ')
        },
        {
            id: BLUSH,
            title: BLUSH.split('-').join(' ')
        } 
    ]
}
export default categories;