import React, { useEffect, useState } from "react";

import Header from "../molecules/Header/Header";
import CategorySelector from "../molecules/CategorySelector/CategorySelector";

import TryNowVisualizer from "../molecules/TryNowVisualizer/TryNowVisualizer";

import VarientSelector from "../molecules/VarientSelector/VarientSelector";
import PostCategoryMetaInfo from "../services/PostCategoryMetaInfo";
import CategoryVarientPostProcessor from "../services/CategoryVarientPostProcessor";
import GetPredefinedModels from "../services/GetPredefinedModels";
import PostUserImage from "../services/PostUserImage";

import PostProcessImage from "../services/PostProcessImage";

import {
  DEFAULT_SCREEN,
  CATEGORY_LIP_COLOR,
  CATEGORY_LIP_LINER,
  CATEGORY_EYE_LINER,
  CATEGORY_EYE_SHADOW,
  CATEGORY_MASCARA,
  IMAGE_VIEWER,
  IMAGE_COMPARER,
  DEFAULT_PAGE_TITLE,
} from "../constants";

import "./DemoStore.scss";

const CATEGORY_PATH = window.location.pathname.split("/")[1];

const DemoStore = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    [
      CATEGORY_LIP_COLOR,
      CATEGORY_LIP_LINER,
      CATEGORY_EYE_LINER,
      CATEGORY_EYE_SHADOW,
      CATEGORY_MASCARA,
    ].includes(CATEGORY_PATH?.toLowerCase())
      ? CATEGORY_PATH?.toLowerCase()
      : CATEGORY_LIP_COLOR
  );
  const [selectedScreen, setSelectedScreen] = useState(DEFAULT_SCREEN);
  const [appliedEffects, setAppliedEffects] = useState();
  const [productVarients, setProductVarients] = useState({});
  const [patterns, setPatterns] = useState({});
  const [colorCode, setColorCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentApiSignal, setCurrentApiSignal] = useState(null);
  const [predefinedModels, setPredefinedModels] = useState([]);
  const [selectedImageDetails, setSelectedImageDetails] = useState("");
  const [baseSelectedImage, setBaseSelectedImage] = useState("");
  const [selectedImageWithoutMakeup, setSelectedImageWithoutMakeup] =
    useState("");

  const updateMetaTags = (meta) => {
    const metaDescription =
      document.querySelector('meta[name="description"]') ||
      document.createElement("meta");
    const ogTitle =
      document.querySelector('meta[property="og:title"]') ||
      document.createElement("meta");
    const ogDescription =
      document.querySelector('meta[property="og:description"]') ||
      document.createElement("meta");
    const ogImage =
      document.querySelector('meta[property="og:image"]') ||
      document.createElement("meta");

    document.title = meta.title ? meta.title : DEFAULT_PAGE_TITLE;
    metaDescription.setAttribute("name", "description");
    metaDescription.content = meta.descriptionContent
      ? meta.descriptionContent
      : "";
    ogTitle.setAttribute("property", "og:title");
    ogTitle.content = meta.ogTitle ? meta.ogTitle : "";
    ogDescription.setAttribute("property", "og:description");
    ogDescription.content = meta.ogDescription ? meta.ogDescription : "";
    ogImage.setAttribute("property", "og:image");
    ogImage.content = meta.ogImage ? meta.ogImage : "";

    document.getElementsByTagName("head")[0].appendChild(metaDescription);
    document.getElementsByTagName("head")[0].appendChild(ogTitle);
    document.getElementsByTagName("head")[0].appendChild(ogDescription);
    document.getElementsByTagName("head")[0].appendChild(ogImage);
  };

  const demoStoreHandler = ({ action, payload }) => {
    switch (action.type) {
      case "get-models": {
        const controller = new AbortController();
        const signal = controller.signal;
        setCurrentApiSignal(controller);
        setLoading(true);

        GetPredefinedModels({ signal })
          .then((res) => res.json())
          .then((res) => {
            setPredefinedModels(res);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setCurrentApiSignal(null);
          });
        break;
      }
      case "process-image": {
        const controller = new AbortController();
        const signal = controller.signal;
        setCurrentApiSignal(controller);
        setLoading(true);
        setAppliedEffects(null);
        PostProcessImage({ payload: { ...payload }, signal })
          .then((res) => res.json())
          .then((res) => {
            setSelectedImageDetails(res);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setCurrentApiSignal(null);
          });
        break;
      }
      case "upload-image": {
        const controller = new AbortController();
        const signal = controller.signal;
        setCurrentApiSignal(controller);
        setLoading(true);
        PostUserImage({ payload: { ...payload }, signal })
          .then((res) => res.json())
          .then((res) => {
            setSelectedImageDetails(res);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setCurrentApiSignal(null);
          });
        break;
      }
      case "select-image":
        setSelectedImageDetails(payload);
        break;
      case "update-effects":
        setAppliedEffects(payload);
        setSelectedImageWithoutMakeup(null);
        break;
      case "set-screen":
        setSelectedScreen(payload);
        setSelectedImageWithoutMakeup(selectedImageDetails);
        break;
      default:
        console.log("unidentified action.");
    }
  };

  useEffect(() => {
    const categoryMetaPromise = PostCategoryMetaInfo({
      payload: { product: selectedCategory },
    }).then((res) => res.json());
    setLoading(true);
    Promise.all([categoryMetaPromise])
      .then((res) => {
        const { metaInfo, ...varientInfo } = { ...res[0].data };
        const { productVariantDetails, ...pattern } =
          CategoryVarientPostProcessor(selectedCategory, varientInfo)();
        setProductVarients(productVariantDetails);
        setPatterns(pattern);
        updateMetaTags(metaInfo);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedImageDetails && !selectedImageDetails.processedURL) {
      setBaseSelectedImage(selectedImageDetails);
    }
    if (!selectedImageWithoutMakeup) {
      setSelectedImageWithoutMakeup(selectedImageDetails);
    }
  }, [selectedImageDetails]);

  return (
    <div className="metapix-demo-store">
      <Header />
      <main className="metapix-app-main">
        <CategorySelector
          action={(catId) => {
            currentApiSignal && currentApiSignal.abort();
            setSelectedCategory(catId);
            if (selectedImageDetails?.processedURL) {
              setSelectedImageDetails({
                id: selectedImageDetails.processedURLId,
                url: selectedImageDetails.processedURL,
              });
            }
            if (selectedScreen === IMAGE_COMPARER) {
              setSelectedScreen(IMAGE_VIEWER);
            }
          }}
          selectedCategory={selectedCategory}
        />

        <section className="try-now">
          <TryNowVisualizer
            imageEffects={appliedEffects}
            predefinedModels={predefinedModels}
            selectedImageDetails={selectedImageDetails}
            baseSelectedImage={baseSelectedImage}
            loading={loading}
            selectedScreen={selectedScreen}
            action={demoStoreHandler}
            selectedImageWithoutMakeup={selectedImageWithoutMakeup}
          />

          <VarientSelector
            productVarients={productVarients}
            productPatterns={patterns}
            action={setAppliedEffects}
            setColorCode={setColorCode}
            appliedEffects={appliedEffects || {}}
            loading={loading}
            selectedScreen={selectedScreen}
            colorCode={colorCode}
          />
        </section>
      </main>
    </div>
  );
};
export default DemoStore;
