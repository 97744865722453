export const DEFAULT_SCREEN = 'default';
export const SELECT_MODEL = 'select-model';
export const IMAGE_VIEWER = 'capured-image-screen';
export const IMAGE_COMPARER = 'compare-image-screen';
export const PHOTO_UPLOADER = 'pic-uploader';
export const CAMERA_VIEWER = 'camera-viewer';

export const CATEGORY_LIP_COLOR = 'lip-color';
export const CATEGORY_LIP_LINER = 'lip-liner';
export const CATEGORY_EYE_LINER = 'eye-liner';
export const CATEGORY_EYE_SHADOW = 'eye-shadow';
export const CATEGORY_MASCARA = 'mascara';
export const BLUSH = 'blush';
export const ALL_SHADES = 'all shades';
export const SHAPE = 'shape';
export const COLORS = 'colors';
export const PATTERNS = 'patterns';

export const CAMERA_CAPTURE_COUNTDOWN = 3;
export const VIDEO_FPS = 60;
export const CAMERA_STATE_START = 'start';
export const CAMERA_STATE_STOP = 'stop';
export const SLIDER_DEFAULT_POSITION = 50;

export const DEFAULT_PAGE_TITLE = "Virtual Makeup Try On | Metapix";