import { BASE_PATH } from "../appConfig";

const urlDictionary = environment => environment === 'mock' ? {
        postCategoryVarient : (category) => {
            const mockFileMap = {
                'lip-color': './__mock__/PostCategoryVarient_lip-color.json',
                'lip-liner': './__mock__/PostCategoryVarient_lip-liner.json',
                'eye-liner': './__mock__/PostCategoryVarient_eye-liner.json',
                'eye-shadow':'./__mock__/PostCategoryVarient_eye_shadow.json',
                'mascara': './__mock__/PostCategoryVarient_mascara.json'
            }
            return mockFileMap[category];
        },
        getPredefinedModels : () => './__mock__/GetPredefinedModels.json',
        postProcessImage : () => './__mock__/PostProcessImage.json',
        postUserImage : () => './__mock__/PostUserImage.json',
        postCategoryMetaInfo : () => './__mock__/PostCategoryMetaInfo.json',
        // getFirstProductVarient : (index) => `${BASE_PATH}/getProductVarient/${index}` // sample with params
    } : {
        postCategoryVarient : () => `${BASE_PATH}/postCategoryVarient`,
        getPredefinedModels : () => `${BASE_PATH}/get-enterprise-models`,
        postProcessImage : () => `${BASE_PATH}/apply-makeup`,
        postUserImage : () => `${BASE_PATH}/create-user-model`,
        postCategoryMetaInfo : () => `${BASE_PATH}/get-meta-info`,
        // getFirstProductVarient : (index) => `${BASE_PATH}/getProductVarient/${index}` // sample with params
    }

export default urlDictionary;
